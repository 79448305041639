<template>
  <div class="pa-1" id="gallery">
    <v-sheet
      class="mx-auto"
      elevation="0"
      style="background-color: transparent"
    >
      <v-slide-group v-model="slide" class="" show-arrows>
        <v-slide-item v-for="(src, i) in photos" :key="i" v-slot="{}">
          <v-card
            class="ma-1"
            id="cardImage"
            width="100"
            height="100"
            @click="index = i"
          >
            <v-tooltip bottom
              ><template v-slot:activator="{ on }">
                <v-icon  v-on="on" id="camera" size="40" color="primary">$camera</v-icon>
                <v-img
                  :src="src"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  v-on="on"
                >
                  <template v-slot:placeholder>
                    <v-row class="ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                  <v-icon large color="primary">mdi-magnify-plus</v-icon>
                </v-img>
              </template>

              {{ title }}
            </v-tooltip>
          </v-card>
        </v-slide-item>
      </v-slide-group></v-sheet
    >

    <CoolLightBox :items="photos" :index="index" @close="index = null">
      <template v-slot:icon-previous>
        <v-icon color="primary" x-large>mdi-chevron-left</v-icon>
      </template>
      <template v-slot:icon-next>
        <v-icon color="primary" x-large>mdi-chevron-right</v-icon>
      </template>
      <template v-slot:close>
        <v-icon color="primary" large>mdi-close</v-icon>
      </template>
    </CoolLightBox>
  </div>
</template>

<script>
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
export default {
  name: "PhotoGalery",
  data() {
    return { index: null, slide: null };
  },
  props: {
    photos: {
      default: () => [],
      type: Array,
    },
    numOfCols: {
      default: 3,
    },
    title: {
      default: "",
    },
  },
  computed: {
    cols() {
      return 12 / this.numOfCols;
    },
  },
  components: {
    CoolLightBox: () => import("vue-cool-lightbox"),
  },
};
</script>
<style lang="sass">
#gallery
  #cardImage
    display: flex
    justify-content: center
    align-items: center
    #camera
      opacity: 0
      position: absolute

    &:hover
      background: rgba(0,0,0,0.8) !important
      opacity: 0.6 !important
      z-index: 3 !important
    &:hover #camera
      opacity: 1 !important
      z-index: 4
  .cool-lightbox-toolbar
    button:nth-child(1),
    button:nth-child(2)
      display: none
  .cool-lightbox__slide__img img
    max-width: 80%
  .col
    padding: 2px !important
    &:hover .v-icon
      opacity: 1

  .v-responsive__content

    display: block
    flex: unset
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    .v-icon
      position: absolute
      top: 45%
      height: 10%
      left: 45%
      width: 10%
      opacity: 0
</style>